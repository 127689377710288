import React from 'react';
import {Nav} from 'react-bootstrap';

import {T} from '../core/Translate';
import {getMainTabIndex, MainTabKey} from '../models/TabKeys';
import {getFirstTabOfMain, getStepsInTab, getTabInfo, isTabAccessible} from '../models/Tabs';
import {useAppSelector} from '../redux';
import {classes} from '../utils/Styles';

import styles from './TabsHeader.module.scss';

export default function TabsHeader() {
  const currentTab = useAppSelector(state => state.session.step);
  const tab = getTabInfo(currentTab);

  return (
    <Nav className={styles.navigation} id="onboardingwizard-top">
      <div className={styles.background} />
      <TabHeader
        title={T('tab.general')}
        name={MainTabKey.General}
        selected={tab.mainTab}
        currentSubStep={tab.subTab}
      />
      <TabHeader
        title={T('tab.electrical')}
        name={MainTabKey.Electrical}
        selected={tab.mainTab}
        currentSubStep={tab.subTab}
      />
      <TabHeader
        title={T('tab.charger')}
        name={MainTabKey.Charger}
        selected={tab.mainTab}
        currentSubStep={tab.subTab}
      />
      <TabHeader
        title={T('tab.confirmation')}
        name={MainTabKey.Confirmation}
        selected={tab.mainTab}
        currentSubStep={tab.subTab}
      />
    </Nav>
  );
}

interface TabHeaderProps {
  title: string;
  name: MainTabKey;
  selected: MainTabKey;
  currentSubStep: number;
}

function TabHeader(props: TabHeaderProps) {
  const {title, name, selected, currentSubStep} = props;
  const [data, photos, status] = useAppSelector(state => [
    state.session.data,
    state.session.photos,
    state.session.status
  ]);
  const index = getMainTabIndex(name);
  const completed = index <= getMainTabIndex(selected);
  const subSteps = getStepsInTab(name);
  const progress = selected === name ? (currentSubStep - 1) / subSteps : completed ? 1 : 0;
  const targetTab = getFirstTabOfMain(name);
  const disabled = !isTabAccessible(targetTab, data, photos, status);

  return (
    <Nav.Item>
      <Nav.Link
        eventKey={name}
        className={classes(styles.tabItem, completed && styles.completed, disabled && styles.disabled)}
      >
        {/*index > 1 && <div className={styles.tabLineLeft} />*/}
        {/*index < numberOfTabs && <div className={styles.tabLineRight} />*/}
        <CircularProgressBar ratio={progress}>
          <div className={styles.tabNumber}>{index}</div>
        </CircularProgressBar>
        <div className={styles.tabLabel}>{title}</div>
      </Nav.Link>
    </Nav.Item>
  );
}

interface CircularProgressBarProps {
  ratio: number;
  children?: React.ReactNode;
}
function CircularProgressBar(props: CircularProgressBarProps) {
  const {ratio, children} = props;
  const rotation = ratio * 360 - 180;

  return (
    <div className={styles.circular}>
      <div className={styles.inner} />
      <div className={styles.children}>
        <div className={styles.children2}>{children}</div>
      </div>
      <div className={styles.grayCircle} />
      {ratio > 0.01 && (
        <div className={styles.circle}>
          <div className={styles.bar} style={{transform: `rotate(${rotation}deg)`}}>
            <div className={styles.progress} />
          </div>
          {ratio < 0.5 && (
            <div className={styles.coverLeft}>
              <div className={styles.coverLeftCircle} />
            </div>
          )}
          {ratio > 0.5 && <div className={styles.coverRight} />}
        </div>
      )}
    </div>
  );
}
