import {imageUrl} from '../utils/Images';
import {classes} from '../utils/Styles';

import styles from './Gallery.module.scss';

interface GallerySelectButtonProps {
  src: string;
  width: number;
  height: number;
  selected: boolean;
  onClick: () => void;
  label?: string | JSX.Element;
  sublabel?: string;
  children?: React.ReactNode;
  noBorder?: boolean;
  disabled?: boolean;
  showBox?: boolean;
  tooltip?: string;
}

export default function GallerySelectButton(props: GallerySelectButtonProps) {
  const {
    src,
    width,
    height,
    children,
    label,
    sublabel,
    selected,
    onClick,
    noBorder,
    disabled,
    showBox = true,
    tooltip
  } = props;

  return (
    <div
      className={classes(styles.selectItem, selected && styles.selected, disabled && styles.disabled)}
      onClick={disabled ? undefined : onClick}
      style={noBorder ? {width, minWidth: width} : undefined}
    >
      <div className={noBorder ? styles.imageWrapperNoBorder : styles.imageWrapper}>
        <img src={imageUrl(src)} alt="" style={{width, height}} title={tooltip} />
      </div>
      <div className={styles.labelWrapper} style={{maxWidth: Math.max(220, noBorder ? width : width + 2 * 24)}}>
        {showBox && (
          <div className={styles.checkbox}>
            <div className={styles.checkboxInner} />
          </div>
        )}
        <div className={styles.label}>
          {children || (
            <p>
              {label}
              {sublabel && (
                <>
                  <br />
                  {sublabel}
                </>
              )}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
