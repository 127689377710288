import React from 'react';

import placeholder from '../assets/images/example.png';

import {Gallery} from './Gallery';
import GallerySelectButton from './GallerySelectButton';
import {RadioGroup, RadioGroupOption} from './RadioGroup';

interface ChoiceGroupProps {
  as: 'radio' | 'buttons';
  label?: string;
  value?: string;
  options: RadioGroupOption[];
  onChange: (value: string) => void;
  width?: number;
  height?: number;
  noBorder?: boolean;
  disabled?: boolean;
  showBox?: boolean;
}

export default function ChoiceGroup(props: ChoiceGroupProps) {
  const {as, label, value, options, onChange, disabled} = props;

  if (as === 'buttons') {
    return <ChoiceGroupGallery {...props} />;
  } else {
    return <RadioGroup label={label} value={value || ''} options={options} setValue={onChange} disabled={disabled} />;
  }
}

function ChoiceGroupGallery(props: ChoiceGroupProps) {
  const {label, value, options, onChange, width = 96, height = 96, noBorder = false, disabled = false, showBox} = props;

  const optionViews = options.map(option => (
    <GallerySelectButton
      key={option.value}
      label={option.label}
      sublabel={option.sublabel}
      src={option.icon || placeholder}
      width={width}
      height={height}
      selected={value === option.value}
      onClick={() => onChange(option.value)}
      noBorder={noBorder}
      disabled={disabled}
      showBox={options.length > 1}
      tooltip={option.tooltip}
    />
  ));

  return (
    <>
      {label && <p>{label}</p>}
      <Gallery>{optionViews}</Gallery>
    </>
  );
}
