import {Modal} from 'react-bootstrap';

import MarkdownView from './MarkdownView';

interface ExplanationModalProps {
  show: boolean;
  title: string;
  explanation: string;
  extraInfo?: () => React.ReactNode;
  onClose: () => void;
}

export default function ExplanationModal(props: ExplanationModalProps) {
  const {show, title, explanation, extraInfo, onClose} = props;

  return (
    <Modal show={show} onHide={onClose} size="lg" style={{color: '#15241f'}}>
      <Modal.Header closeButton>{title}</Modal.Header>
      <Modal.Body style={{fontSize: 16}}>
        <>
          <MarkdownView content={explanation} />
          {extraInfo && extraInfo()}
        </>
      </Modal.Body>
    </Modal>
  );
}
