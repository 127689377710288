import {AdminViewableSession} from '../models/AdminViewableSession';
import {ConfiguratorData, migrateConfiguration} from '../models/ConfiguratorData';
import {Discount} from '../models/Discount';
import {PublicPrices} from '../models/PublicPrices';
import {Quote} from '../models/Quote';
import {SessionStatus, StoredSessionData} from '../models/SessionData';
import {TabKey} from '../models/TabKeys';
import {OrderStatus} from '../server/models/Orders';

import {getCurrentLanguage} from './Translate';

export class API {
  private baseUrl: string;
  private cachedPrices?: Promise<PublicPrices>;

  private guardedFetch(url: string, init: RequestInit) {
    return fetch(url, init).then(response => {
      if (response.ok) {
        return response;
      } else {
        console.log('not ok!', response);
        throw {status: response.status};
      }
    });
  }

  private get<T>(path: string, token?: string): Promise<T> {
    const headers: HeadersInit = {};
    if (token !== undefined) {
      headers.token = token;
    }

    return this.guardedFetch(this.baseUrl + path, {headers}).then(response => response.json());
  }

  private put<T>(path: string, data: object, token?: string): Promise<T> {
    const headers: HeadersInit = {
      'Content-Type': 'application/json'
    };
    if (token !== undefined) {
      headers.token = token;
    }

    return this.guardedFetch(this.baseUrl + path, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers
    }).then(response => response.json());
  }

  private post<T>(path: string, data: object, token?: string): Promise<T> {
    const headers: HeadersInit = {
      'Content-Type': 'application/json'
    };
    if (token !== undefined) {
      headers.token = token;
    }

    return this.guardedFetch(this.baseUrl + path, {
      method: 'POST',
      body: JSON.stringify(data),
      headers
    }).then(response => response.json());
  }

  private patch<T>(path: string, data: object, token?: string): Promise<T> {
    const headers: HeadersInit = {
      'Content-Type': 'application/json'
    };
    if (token !== undefined) {
      headers.token = token;
    }

    return this.guardedFetch(this.baseUrl + path, {
      method: 'PATCH',
      body: JSON.stringify(data),
      headers
    }).then(response => response.json());
  }

  private delete(path: string, token?: string): Promise<void> {
    return this.guardedFetch(this.baseUrl + path, {
      method: 'DELETE',
      headers: token ? {token} : undefined
    }).then(() => {});
  }

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  createSession(data: ConfiguratorData): Promise<StoredSessionData> {
    return this.post('/api/session', {
      step: TabKey.Information,
      language: getCurrentLanguage(),
      data
    });
  }

  async loadSession(token: string): Promise<StoredSessionData | undefined> {
    const session = (await this.get('/api/session', token)) as StoredSessionData | undefined;
    if (session) {
      session.data = migrateConfiguration(session.data);
    }
    return session;
  }

  updateSession(token: string, updates: Partial<ConfiguratorData>, step: TabKey) {
    return this.patch(`/api/session`, {data: updates, step, language: getCurrentLanguage()}, token);
  }

  async getDiscount(actionCode: string): Promise<Discount> {
    return this.get(`/api/discountcodes/${actionCode}`);
  }

  async deleteSession(token: string): Promise<boolean> {
    await this.delete('/api/session', token);
    return Promise.resolve(true);
  }

  async login(username: string, password: string): Promise<LoginResponse> {
    return this.post('/api/login', {username, password});
  }

  async getAllSessions(token: string): Promise<{sessions: AdminViewableSession[]}> {
    return this.get('/api/sessions', token);
  }

  async searchSessions(
    token: string,
    query?: string,
    project?: string,
    status?: SessionStatus,
    offset?: number,
    limit?: number
  ): Promise<{sessions: AdminViewableSession[]; total: number}> {
    const params = new URLSearchParams();
    if (query) {
      params.append('query', query || '');
    }
    if (project) {
      params.append('project', project || '');
    }
    if (status) {
      params.append('status', status || '');
    }
    if (offset) {
      params.append('offset', offset.toString());
    }
    if (limit) {
      params.append('limit', limit.toString());
    }
    return this.get(`/api/sessions/search?${params.toString()}`, token);
  }

  async deletePhoto(token: string, id: number): Promise<unknown> {
    return this.delete(`/api/photos/${id}`, token);
  }

  getPrices(): Promise<PublicPrices> {
    if (this.cachedPrices === undefined) {
      this.cachedPrices = this.get(`/api/prices`);
    }

    return this.cachedPrices;
  }

  async generateQuote(token: string, actionCode?: string): Promise<Quote> {
    return this.get(`/api/quote${actionCode ? `?actionCode=${encodeURIComponent(actionCode)}` : ''}`, token);
  }

  async checkQuoteStatus(token: string): Promise<{quoteStatus: OrderStatus; sessionStatus: SessionStatus}> {
    return this.post(`/api/quote/checkstatus`, {}, token);
  }

  async checkSurveyQuoteStatus(token: string): Promise<{quoteStatus: OrderStatus; sessionStatus: SessionStatus}> {
    return this.post(`/api/surveyquote/checkstatus`, {}, token);
  }

  async createRemoteSurveyQuote(token: string): Promise<void> {
    return this.post(`/api/surveyquote/create`, {}, token);
  }

  async submitMySessionForExecution(token: string): Promise<{sessionStatus: SessionStatus}> {
    return this.post(`/api/session/submit`, {}, token);
  }

  async submitSessionForExecution(sessionId: number, token: string): Promise<void> {
    return this.post(`/api/session/${sessionId}/submit`, {}, token);
  }

  getSessionPDFUrl(id: number, token: string) {
    return `${this._getBaseUrl()}/sessionpdf/${id}?token=${token}`;
  }

  getFileUploadUrl() {
    return `${this._getBaseUrl()}/api/photoupload`;
  }

  getPhotoUrl(url: string) {
    return this._getBaseUrl() + url;
  }

  getQuoteDownloadUrl(token: string) {
    return `${this._getBaseUrl()}/quotepdf?token=${token}`;
  }

  getSignQuoteUrl(token: string) {
    return `${this._getBaseUrl()}/signquote?token=${token}`;
  }

  getSignSurveyQuoteUrl(token: string) {
    return `${this._getBaseUrl()}/signsurveyquote?token=${token}`;
  }

  _getBaseUrl() {
    return window.location.host.includes('localhost') ? 'http://localhost:4100' : this.baseUrl;
  }
}

type LoginResponse = {status: 'invalid'} | {status: 'ok'; token: string};

function getBaseUrl() {
  const host = window.location.host;
  if (host.endsWith('staging.smappee.com')) {
    //    return 'https://dash-staging.smappee.net/evonboardingwizard';
    return 'https://onboardingwizard-staging.smappee.net';
  } else if (host.endsWith('smappee.com')) {
    return 'https://onboardingwizard.smappee.net';
  } else {
    return '';
  }
}

export const api = new API(getBaseUrl());
