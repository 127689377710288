import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';

// bootstrap styles provided by site-main.css
//import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';
import './Fontawesome';

import {createStore} from 'redux';

import {version} from '../package.json';

import AdminApp from './admin/AdminApp';
import App from './App';
import AutoSaver from './components/AutoSaver';
import {setCurrentLanguage} from './core/Translate';
import {registerIcons} from './Fontawesome';
import {TabKey} from './models/TabKeys';
import {exportTabsToCSV} from './models/Tabs';
import {AnyAppAction} from './redux/Actions';
import {IAppState} from './redux/AppState';
import createRootReducer from './redux/Reducer';
import reportWebVitals from './reportWebVitals';
import {autodetectLanguage} from './utils/AutodetectLanguage';

registerIcons();
setCurrentLanguage(autodetectLanguage());

const query = new URLSearchParams(window.location.search ? window.location.search.substr(1) : undefined);
if (query.has('clear')) {
  localStorage.clear();
  window.location.search = '';
}
let projectId = query.get('project') || undefined;
let employeeNumber = query.has('en');
let params = {projectId, employeeNumber};

(window as any).printTabs = () => {
  const lines = exportTabsToCSV();
  console.log(lines.join('\n'));
};

const store = createStore<IAppState, AnyAppAction, {}, {}>(createRootReducer());

const element = document.getElementsByClassName('ev-onboarding-wizard')[0];
if (query.has('admin')) {
  ReactDOM.render(
    <React.StrictMode>
      <AdminApp />
    </React.StrictMode>,
    element
  );
} else {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <App goToTab={(query.get('tab') as TabKey) || undefined} params={params} />
        <AutoSaver />
      </Provider>
      <p className="version">Onboarding wizard {version}</p>
    </React.StrictMode>,
    element
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
