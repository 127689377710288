import {combineReducers} from 'redux';

import {DefaultConfiguratorData} from '../models/ConfiguratorData';
import {SessionStatus, StoredSessionData} from '../models/SessionData';
import {TabKey} from '../models/TabKeys';
import {autodetectLanguage} from '../utils/AutodetectLanguage';

import {AnyAppAction, AppActionType} from './Actions';
import {IAppState} from './AppState';

const initialSessionState: StoredSessionData = {
  token: '',
  step: TabKey.Information,
  data: DefaultConfiguratorData,
  status: SessionStatus.Filling,
  language: autodetectLanguage(),
  photos: []
};

function session(state: StoredSessionData = initialSessionState, action: AnyAppAction) {
  switch (action.type) {
    case AppActionType.SetSessionToken:
      return {...state, token: action.payload.token};
    case AppActionType.SetStep:
      return {...state, step: action.payload};
    case AppActionType.ClearSession:
      return initialSessionState;
    case AppActionType.UpdateSession:
      return {...state, data: {...state.data, ...action.payload}};
    case AppActionType.LoadSession:
      return {...state, ...action.payload};
    case AppActionType.AddPhotos: {
      const newPhotos = [...state.photos, ...action.payload];
      return {...state, photos: newPhotos};
    }
    case AppActionType.DeletePhoto: {
      const newPhotos = state.photos.filter(photo => photo.id !== action.payload);
      return {...state, photos: newPhotos};
    }
    case AppActionType.SetSessionStatus:
      return {...state, status: action.payload};
    default:
      return state;
  }
}

function ready(state: boolean = false, action: AnyAppAction) {
  switch (action.type) {
    case AppActionType.LoadSession:
      console.log('loadsession');
      return true;
    case AppActionType.Ready:
      console.log('ready');
      return true;
    default:
      return state;
  }
}

function loadError(state: boolean = false, action: AnyAppAction) {
  switch (action.type) {
    case AppActionType.LoadSession:
      return false;
    case AppActionType.MarkLoadError:
      return true;
    default:
      return state;
  }
}

function saveError(state: boolean = false, action: AnyAppAction) {
  switch (action.type) {
    case AppActionType.MarkSaveError:
      return true;
    case AppActionType.ClearSaveError:
      return false;
    default:
      return state;
  }
}

export default function createRootReducer() {
  return combineReducers<IAppState, AnyAppAction>({session, ready, loadError, saveError});
}
